import { AbsoluteCenteredLoader } from "@novel/shared/components/CenteredLoader";
import { vendorRouteReplace } from "@vendor-portal/definitions/routeDefinitions";
import { useActiveOrganizationSelector } from "@vendor-portal/redux/reducers/vendorUserOrganizations";
import { useNovelVendorSelector } from "@vendor-portal/redux/reduxHooks";
import React, { useEffect } from "react";
const IndexPage = (): JSX.Element => {
  const org = useActiveOrganizationSelector();
  const {
    isLoading: gatedExperiencesIsLoading,
    data: gatedExperiences
  } = useNovelVendorSelector(state => state.gatedExperiences);
  const gatedExperiencesLength = gatedExperiences.length;
  useEffect(() => {
    if (org?.canSeeWalletPass) {
      vendorRouteReplace("/wallet-pass");
    } else if (!gatedExperiencesIsLoading && !gatedExperiencesLength) {
      vendorRouteReplace("/setup");
    } else {
      vendorRouteReplace("/gated-experience");
    }
  }, [gatedExperiencesIsLoading, org, gatedExperiencesLength]);
  return <AbsoluteCenteredLoader />;
};
export default IndexPage;